import React from "react";
import clsx from "clsx";
import MenuItem from "../MenuItem";

import styles from "./Level3.styles";

const Level3 = ({ items, selectedMenuLevel2ID }) => {
	const css = styles();

	if (!items || items.length === 0) return null;

	return (
		<div
			className={clsx(css.level3, "mco-view-component-desktopmenu__level3")}
			key={selectedMenuLevel2ID}
		>
			{items.map(subitem => (
				<MenuItem key={subitem.id} item={subitem} level={3} selected={subitem.selected} />
			))}
		</div>
	);
};

export default Level3;
