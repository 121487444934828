import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	level3: {
		borderLeft: "1px solid #848890",
		display: "flex",
		flexDirection: "column",
		paddingLeft: theme.spacing(3),
		"& a:last-child": {
			marginBottom: 0
		}
	}
}));

export default styles;
