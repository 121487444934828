import React, { useContext, useState } from "react";
import clsx from "clsx";
import PageContext from "@PageContext";
import { motion } from "framer-motion";
import MenuItem from "../MenuItem";
import Level3 from "../Level3";
import ReactHTMLPicture from "@micado-digital/react-htmlpicture/ReactHTMLPicture";

import styles from "./Level2.styles";

const Level2 = ({
	handleMouseEnterLevel2,
	handleMouseLeaveLevel2,
	headerScroll,
	items,
	menuLevel2Ref
}) => {
	const [selectedMenuLevel2ID, setSelectedMenuLevel2ID] = useState();
	const [pageData] = useContext(PageContext);
	const layoutVariant = pageData?.layoutVariant;
	const { REACT_APP_PATH } = process.env;
	const css = styles();

	const handleLevel2Click = level2ID => {
		if (level2ID === selectedMenuLevel2ID) {
			setSelectedMenuLevel2ID(null);
			return;
		}

		setSelectedMenuLevel2ID(level2ID);
	};

	const animation = {
		hidden: { opacity: 0 },
		active: {
			opacity: 1,
			transition: {
				duration: 0.5,
				ease: "easeInOut"
			}
		}
	};

	return (
		<div
			className={clsx(css.level2, "mco-view-component-header-menu-desktopmenu-level2", {
				[css.level2Fullscreen]: layoutVariant !== "no-animation" && headerScroll === false,
				[css.menuLevel3Active]: selectedMenuLevel2ID
			})}
			onMouseEnter={handleMouseEnterLevel2}
			onMouseLeave={handleMouseLeaveLevel2}
		>
			<div>
				{items?.map(item => (
					<div data-id={item.id} key={item.id}>
						<MenuItem
							connectorRef={menuLevel2Ref}
							hasChildren={item.items?.length > 0}
							item={item}
							level={2}
							onClick={handleLevel2Click}
							selected={item.id === selectedMenuLevel2ID}
						/>
					</div>
				))}
			</div>
			{items?.map(
				item =>
					item.id === selectedMenuLevel2ID && (
						<motion.div key={item?.id} initial="hidden" animate="active" variants={animation}>
							<Level3 items={item.items} />
						</motion.div>
					)
			)}
			{items?.map(
				item =>
					item.id === selectedMenuLevel2ID &&
					item?.media && (
						<motion.div
							className={css.image}
							initial={{ x: -20, opacity: 0 }}
							animate={{ x: 0, opacity: 1 }}
							key={item?.id}
							transition={{ duration: 0.8 }}
						>
							<ReactHTMLPicture
								media={item?.media}
								path={REACT_APP_PATH}
								webpFallback={true}
								xs="menu"
							/>
						</motion.div>
					)
			)}
		</div>
	);
};

export default Level2;
