import React, { useContext } from "react";
import clsx from "clsx";
import ReactLink from "@micado-digital/react-link/ReactLink";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";
import UiContext from "@UiContext";

import styles from "./MenuItem.styles";

const MenuItem = ({ childrenVisible, item, level, onClick }) => {
	const css = styles();
	const { link, linkRel, selected, target, title } = item;
	const [ui, setUi] = useContext(UiContext);

	return (
		<ReactLink
			className={clsx(css.menuItem, "mco-view-component-header-menu-mobilemenu-menuitem", {
				[css.level1]: level === 1,
				"mco-view-component-header-menu-mobilemenu-menuitem__level1": level === 1,
				[css.level1Selected]: level === 1 && selected,
				"mco-view-component-header-menu-mobilemenu-menuitem__level1--selected":
					level === 1 && selected,
				[css.level2]: level === 2,
				"mco-view-component-header-menu-mobilemenu-menuitem__level2": level === 2,
				[css.level2Selected]: level === 2 && selected,
				"mco-view-component-header-menu-mobilemenu-menuitem__level2--selected":
					level === 2 && selected,
				[css.level3]: level === 3,
				"mco-view-component-header-menu-mobilemenu-menuitem__level3": level === 3,
				[css.level3Selected]: level === 3 && selected,
				"mco-view-component-header-menu-mobilemenu-menuitem__level3--selected":
					level === 3 && selected,
				[css.childrenVisible]: childrenVisible,
				"mco-view-component-header-menu-mobilemenu-menuitem__childrenVisible": childrenVisible
			})}
			// onClick={e => onClick && onClick(e, item)}
			onClick={e => {
				if (item?.items) {
					e.preventDefault();
					onClick(e, item);
					return;
				}
				setUi({ ...ui, menuOpen: false });
			}}
			rel={linkRel}
			target={target}
			to={link}
		>
			{title}
			{item?.items && item?.items?.length > 0 && (
				<ReactSVGIcon
					className={clsx(
						css.arrow,
						"mco-view-component-header-menu-mobilemenu-menuitem__arrow"
					)}
					src="/img/icons/chevron-down.svg"
					size={20}
				/>
			)}
		</ReactLink>
	);
};

export default MenuItem;
