import React, { useState } from "react";
import clsx from "clsx";
import Container from "@Components/views/Container";
import Logo from "./Logo";
import Menu from "./Menu";

import styles from "./Row2.styles";

const Row2 = ({ headerScroll, layoutVariant }) => {
	const css = styles();
	const [desktopMenuVisible, setDesktopMenuVisible] = useState(false);

	const submenuOpen = state => {
		setDesktopMenuVisible(state);
	};

	return (
		<div
			className={clsx(css.row2, "mco-view-component-header-row2", {
				[css.headerScroll]: headerScroll,
				[css.headerInvisible]: layoutVariant !== "no-animation",
				[css.menuVisible]: desktopMenuVisible
			})}
		>
			<Container className={clsx(css.container, "mco-view-component-header-row2__container")}>
				<Logo headerScroll={headerScroll} />
				<Menu headerScroll={headerScroll} submenuVisible={submenuOpen} />
			</Container>
		</div>
	);
};

export default Row2;
