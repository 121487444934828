import React, { useContext } from "react";
import clsx from "clsx";
import WebProfileContext from "@WebProfileContext";
import Arrival from "./Arrival";
import Jobs from "./Jobs";
import Phone from "./Phone";
import Mail from "./Mail";
import Region from "./Region";
import Hospital from "./Hospital";
import SocialMediaIcons from "./SocialMediaIcons";

import styles from "./Links.styles";

const Links = () => {
	const [profileData] = useContext(WebProfileContext);
	const css = styles();

	const linkArrival = profileData?.customLinks?.find(
		item => item?.identifier === "header-arrival"
	);
	const linkJobs = profileData?.customLinks?.find(item => item?.identifier === "header-jobs");
	const linkRegion = profileData?.customLinks?.find(item => item?.identifier === "header-region");
	const linkHospital = profileData?.customLinks?.find(item => item?.identifier === "header-hospital");

	return (
		<div className={clsx(css.links, "mco-view-component-header-menu-mobilemenu__links")}>
			<div className={clsx(css.row01, "mco-view-component-header-menu-mobilemenu__links-row01")}>
				<Arrival link={linkArrival} />
				<Jobs link={linkJobs} />
				<Region link={linkRegion} />
				<Hospital link={linkHospital} />
			</div>
			<div className={clsx(css.row02, "mco-view-component-header-menu-mobilemenu__links-row02")}>
				<Phone />
				<Mail />
			</div>
			<div className={clsx(css.row03, "mco-view-component-header-menu-mobilemenu__links-row03")}>
				<SocialMediaIcons />
			</div>
		</div>
	);
};

export default Links;
