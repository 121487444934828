import React, { useContext } from "react";
import clsx from "clsx";
import PageContext from "@PageContext";
import ReactLink from "@micado-digital/react-link/ReactLink";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";
import UiContext from "@UiContext";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import Local from "@Local";
import localDE from "@localDE";
import localEN from "@localEN";

import styles from "./Hospital.styles";

const Hospital = ({ link }) => {
	const [pageData] = useContext(PageContext);
	const [ui, setUi] = useContext(UiContext);
	const { lang } = pageData;
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});
	const css = styles();

	return (
		<div className={clsx(css.hospital, "mco-view-component-header-hospital")}>
			<ReactLink
				onClick={e => {
					setUi({ ...ui, menuOpen: false });
				}}
				rel="noreferrer"
				target="_blank"
				to={link?.url}
			>
				<ReactSVGIcon src="/img/icons/hospital.svg" size={20} />
				<span className={clsx(css.label, "mco-view-component-header-water__hospital")}>
					<Local identifier="header.hospital" l={l} />
				</span>
			</ReactLink>
		</div>
	);
};

export default Hospital;
