import React from "react";
import clsx from "clsx";
import MenuItem from "../MenuItem";

import styles from "./Level3.styles";

const Level3 = ({ items }) => {
	const css = styles();

	return (
		<div className={clsx(css.level3, "mco-view-component-header-menu-mobilemenu-level3")}>
			{items?.map(item => {
				const { id } = item;

				return (
					<div key={id}>
						<MenuItem item={item} level={3} />
					</div>
				);
			})}
		</div>
	);
};

export default Level3;
