import React, { useContext } from "react";
import clsx from "clsx";
import WebProfileContext from "@WebProfileContext";
import ReactLink from "@micado-digital/react-link/ReactLink";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";

import styles from "./SocialMediaIcons.styles";

const SocialMediaIcons = ({ className }) => {
	const css = styles();
	const [profileData] = useContext(WebProfileContext);
	const { links } = profileData;

	return (
		<ul
			className={clsx(
				css.socialIcons,
				className,
				"mco-view-component-header-menu__social-icons"
			)}
		>
			{links?.map((item, counter) => {
				const { type, title, url } = item;
				return <Item key={counter} type={type} title={title} url={url} />;
			})}
		</ul>
	);
};

const Item = ({ type, title, url }) => {
	const css = styles();

	return (
		<li className="mco-view-component-header-menu__social-icons-item">
			<ReactLink rel="noopener noreferrer" target="_blank" title={title} to={url}>
				<ReactSVGIcon
					className={clsx(css.icon, "mco-view-component-header-menu__social-icons-icon")}
					size={24}
					src={`/img/icons/${type}.svg`}
				/>
			</ReactLink>
		</li>
	);
};

export default SocialMediaIcons;
