import React from "react";
import clsx from "clsx";
import MenuItem from "../MenuItem";

import styles from "./Level1.styles";

const Level1 = ({
	handleMouseEnterLevel1,
	handleMouseLeaveLevel1,
	handleClickLevel1,
	items
}) => {
	const css = styles();

	return (
		<div className={clsx(css.level1, "mco-view-component-header-menu-desktopmenu-level1")}>
			{items?.map(item => (
				<MenuItem
					item={item}
					key={item.id}
					level={1}
					onClick={handleClickLevel1}
					onMouseEnter={handleMouseEnterLevel1}
					onMouseLeave={handleMouseLeaveLevel1}
				/>
			))}
		</div>
	);
};

export default Level1;
