import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	elements: {
		display: "flex",
		flexDirection: "column",
		gap: theme.spacing(7),
		"& > *:first-child:not(.mco-collapsing)": {
			marginTop: theme.spacing(7)
		},
		"& > *:last-child:not(.mco-collapsing)": {
			marginBottom: theme.spacing(7)
		},
		[theme.breakpoints.up("md")]: {
			gap: theme.spacing(10),
			"& > *:first-child:not(.mco-collapsing)": {
				marginTop: theme.spacing(10.5)
			},
			"& > *:last-child:not(.mco-collapsing)": {
				marginBottom: theme.spacing(10.5)
			}
		},
		"& .mco-dropdown-01__details > div": {
			display: "flex",
			flexDirection: "column",
			gap: theme.spacing(4),
			padding: theme.spacing(4, 0)
		},
		"& .mco-view-component-container-basic-singleteaser--background::before": {
			backgroundImage: "url('/img/cube.webp')",
			backgroundSize: "contain",
			backgroundRepeat: "no-repeat",
			backgroundPosition: "top left",
			content: "''",
			height: "100%",
			maxHeight: "300px",
			left: -100,
			opacity: 0.8,
			position: "absolute",
			pointerEvents: "none",
			top: -48,
			width: "100%",
			[theme.breakpoints.up("md")]: {
				left: -140,
				maxHeight: "85%",
				top: -76
			}
		}
	}
}));

export default styles;
