import React, { useContext } from "react";
import PageContext from "@PageContext";
import { useScrollYPosition } from "react-use-scroll-position";
import Row1 from "./Row1";
import Row2 from "./Row2";

const Header = () => {
	const [pageData] = useContext(PageContext);
	const layoutVariant = pageData?.layoutVariant;
	const scrollY = useScrollYPosition();
	const headerScroll = scrollY > 96;

	return (
		<>
			<Row1 layoutVariant={layoutVariant} />
			<Row2 headerScroll={headerScroll} layoutVariant={layoutVariant} />
		</>
	);
};

export default Header;
