import React, { useContext } from "react";
import { useSearchParams } from "react-router-dom";
import useSystemLinks from "@micado-digital/react-ui/hooks/useSystemLinks";
import clsx from "clsx";
import PageContext from "@PageContext";
import groupFollowingElementsByTag from "@micado-digital/react-ui/utils/groupFollowingElementsByTag";
import groupExactlyElements from "@micado-digital/react-ui/utils/groupExactlyElements";
import getJSXElement from "./getJSXElement";

import styles from "./Elements.styles";

const Elements = () => {
	const css = styles();
	const [pageData] = useContext(PageContext);
	let { elements, lang } = pageData;
	const [search] = useSearchParams();
	const sysLink = useSystemLinks();

	// group teaser

	elements = groupExactlyElements(elements, "extension-teaser-combo", [
		"extension-icon-teaser",
		"basic-singleteaser"
	]);

	// group infobox

	elements = groupExactlyElements(elements, "extension-infobox-combo", [
		"basic-headline",
		"basic-headline",
		"basic-text",
		"extension-infobox"
	]);

	// group downloads

	elements = groupFollowingElementsByTag(elements, "basic-download-group", "basic-download");

	// group dropdowns

	elements = groupFollowingElementsByTag(
		elements,
		"basic-dropdown-group",
		["basic-dropdown", "*"],
		"basic-dropdown",
		"basic-dropdown"
	);

	return (
		<div className={clsx(css.elements, "mco-elements")}>
			{elements?.map(element => {
				return getJSXElement(element, lang, search, sysLink);
			})}
		</div>
	);
};

export default Elements;
