import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	mobilemenu: {
		alignItems: "center",
		display: "flex",
		marginLeft: "auto",
		order: 2
	},
	container: {
		background: theme.palette.background.menu,
		display: "flex",
		flexDirection: "column",
		height: "100vh",
		left: 0,
		overflow: "hidden",
		padding: theme.spacing(2.5, 3),
		position: "absolute",
		right: 0,
		top: 0,
		"& .mco-view-component-header-lang": {
			color: theme.palette.text.primary,
			left: theme.spacing(2),
			padding: theme.spacing(1),
			position: "absolute",
			top: theme.spacing(2)
		},
		"& .mco-view-component-header-search": {
			marginTop: theme.spacing(0.5),
			"& .mco-view-component-header-search__label": {
				color: theme.palette.primary.main
			}
		}
	}
}));

export default styles;
