import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	socialIcons: {
		display: "flex",
		flexWrap: "wrap",
		"& .mco-view-component-header-menu__social-icons-item + .mco-view-component-header-menu__social-icons-item": {
			marginLeft: 24
		}
	},
	icon: {
		"& svg path": {
			fill: theme.palette.primary.dark
		}
	}
}));

export default styles;
