import React, { useContext } from "react";
import clsx from "clsx";
import PageContext from "@PageContext";
import ReactLink from "@micado-digital/react-link/ReactLink";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import useSystemLinks from "@micado-digital/react-ui/hooks/useSystemLinks";
import Local from "@Local";
import localDE from "@localDE";
import localEN from "@localEN";

import styles from "./Jobs.styles";

const Jobs = () => {
	const [pageData] = useContext(PageContext);
	const sysLink = useSystemLinks();
	const { lang } = pageData;
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});
	const css = styles();

	return (
		<div className={clsx(css.jobs, "mco-view-component-header-jobs")}>
			<ReactLink to={sysLink("header-jobs")} target="_blank" rel="noreferrer">
				<ReactSVGIcon src="/img/icons/jobs.svg" size={20} />
				<span className={clsx(css.label, "mco-view-component-header-water__jobs")}>
					<Local identifier="header.jobs" l={l} />
				</span>
			</ReactLink>
		</div>
	);
};

export default Jobs;
