import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import loadable from "@loadable/component";
import PageContext from "@PageContext";
import UiContext from "@UiContext";
import useSystemLinks from "@micado-digital/react-ui/hooks/useSystemLinks";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import Local from "@Local";
import localDE from "@localDE";
import localEN from "@localEN";

import styles from "./Search.styles";

const ReactSearchOverlay01 = loadable(() =>
	import("@micado-digital/react-search-overlay/ReactSearchOverlay01")
);

const Search = () => {
	const sysLink = useSystemLinks();
	const [pageData] = useContext(PageContext);
	const [searchOpen, setSearchOpen] = useState(false);
	const [ui, _setUi] = useContext(UiContext);
	const { _loading } = ui;
	const { lang } = pageData;
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});
	const css = styles();

	useEffect(() => {
		if (_loading) {
			_setUi({ menuOpen: false });
		}
	}, [_loading]); // eslint-disable-line react-hooks/exhaustive-deps

	return (
		<>
			<div
				className={clsx(css.search, "mco-view-component-header-search")}
				onClick={() => setSearchOpen(true)}
			>
				<ReactSVGIcon color="primary" src="/img/icons/search.svg" size={24} />
				<span className={clsx(css.label, "mco-view-component-header-search__label")}>
					<Local identifier="header.search" l={l} />
				</span>
			</div>
			{searchOpen && (
				<ReactSearchOverlay01
					lang={lang}
					setSearchOpen={setSearchOpen}
					targetLink={sysLink("search")}
				/>
			)}
		</>
	);
};

export default Search;
