import React, { useContext } from "react";
import clsx from "clsx";
import PageContext from "@PageContext";
import ReactLink from "@micado-digital/react-link/ReactLink";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";
import UiContext from "@UiContext";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import Local from "@Local";
import localDE from "@localDE";
import localEN from "@localEN";

import styles from "./Region.styles";

const Region = ({ link }) => {
	const [pageData] = useContext(PageContext);
	const [ui, setUi] = useContext(UiContext);
	const { lang } = pageData;
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});
	const css = styles();

	return (
		<div className={clsx(css.region, "mco-view-component-header-region")}>
			<ReactLink
				to={link?.url}
				onClick={e => {
					setUi({ ...ui, menuOpen: false });
				}}
			>
				<ReactSVGIcon src="/img/icons/region.svg" size={20} />
				<span className={clsx(css.label, "mco-view-component-header-water__region")}>
					<Local identifier="header.region" l={l} />
				</span>
			</ReactLink>
		</div>
	);
};

export default Region;
