import React, { useContext, useEffect, useRef, useState } from "react";
import clsx from "clsx";
import { motion } from "framer-motion";
import { scrollbarWidth } from "@xobotyi/scrollbar-width";
import PageContext from "@PageContext";
import UiContext from "@UiContext";
import Level1 from "./Level1";
import Level2 from "./Level2";
import Search from "../Search";

import styles from "./Desktopmenu.styles";

const Desktopmenu = ({ headerScroll, menuGroup, submenuVisible }) => {
	const css = styles();
	const [pageData] = useContext(PageContext);
	const [ui, _setUi] = useContext(UiContext);
	const { submenuOpen } = ui;
	const setUi = state => {
		_setUi({ ...ui, submenuOpen: state });
	};
	const [currentmenu, setCurrentmenu] = useState({});
	const mouseOverTimer = useRef(null);
	const { items: currentmenuSubmenuItems } = currentmenu;
	const closeTimer = useRef(null);
	const showMainMenuTimer = useRef(null);
	const { items } = menuGroup;
	const scrollBarWidth = scrollbarWidth();

	useEffect(() => {
		if (submenuOpen && currentmenuSubmenuItems && currentmenuSubmenuItems.length > 0) {
			document.body.classList.add("menu-active");
			document.body.style.overflow = `hidden`;
			submenuVisible(true);
		} else {
			document.body.classList.remove("menu-active");
			document.body.style.overflow = `unset`;
			submenuVisible(false);
		}
	}, [scrollBarWidth, submenuOpen, submenuVisible, currentmenuSubmenuItems]);

	const handleClickLevel1 = (e, item) => {
		if (item.items && item.items?.length > 0) {
			if (new Date().getTime() - mouseOverTimer.current.getTime() <= 30) {
				e.preventDefault();
			}
		}
	};

	const handleMouseEnterLevel1 = (e, item) => {
		avoidCloseMenu();
		showSubmenu(item);
		mouseOverTimer.current = new Date();
	};

	const handleMouseLeaveLevel1 = (e, item) => {
		closeSubmenuWithTimeout();
	};

	const handleMouseEnterLevel2 = (e, item) => {
		avoidCloseMenu();
	};

	const handleMouseLeaveLevel2 = (e, item) => {
		closeSubmenuWithTimeout();
	};

	const showSubmenu = item => {
		showMainMenuTimer.current = setTimeout(() => {
			setUi(true);
			setCurrentmenu(item);
		}, 100);
	};

	const avoidCloseMenu = () => {
		clearInterval(closeTimer.current);
	};

	const closeSubmenuWithTimeout = () => {
		closeTimer.current = setTimeout(() => {
			setUi(false);
		}, 500);
	};

	const avoidOpenMenu = () => {
		clearInterval(showMainMenuTimer.current);
	};

	const animation = {
		hidden: { opacity: 0 },
		active: {
			opacity: 1,
			transition: {
				duration: 0.5,
				ease: "easeInOut"
			}
		}
	};

	return (
		<div className={clsx(css.desktopmenu, "mco-view-component-header-menu-desktopmenu")}>
			<div
				className={clsx(css.content, "mco-view-component-header-menu-desktopmenu__content", {
					"mco-view-component-header-menu-desktopmenu__content--scroll":
						headerScroll || pageData?.layoutVariant === "no-animation"
				})}
				onMouseLeave={() => avoidOpenMenu()}
			>
				<Level1
					handleClickLevel1={handleClickLevel1}
					handleMouseEnterLevel1={handleMouseEnterLevel1}
					handleMouseLeaveLevel1={handleMouseLeaveLevel1}
					items={items}
				/>
				<Search />
			</div>
			{submenuOpen && currentmenuSubmenuItems && currentmenuSubmenuItems.length > 0 && (
				<motion.div initial="hidden" animate="active" variants={animation}>
					<Level2
						handleMouseEnterLevel2={handleMouseEnterLevel2}
						handleMouseLeaveLevel2={handleMouseLeaveLevel2}
						headerScroll={headerScroll}
						items={currentmenuSubmenuItems}
					/>
				</motion.div>
			)}
		</div>
	);
};

export default Desktopmenu;
