import React, { useContext } from "react";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import ReactLink from "@micado-digital/react-link/ReactLink";
import PageContext from "@PageContext";
import WebProfileContext from "@WebProfileContext";
import Log from "@Log";

import styles from "./Logo.styles";

const Logo = ({ headerScroll }) => {
	const [profileData] = useContext(WebProfileContext);
	const [pageData] = useContext(PageContext);
	const layoutVariant = pageData?.layoutVariant;
	const css = styles();

	if (isEmpty(profileData)) return <></>;

	const { logos, name } = profileData;

	const logoSrc = logos?.find(item => item.identifier === "logo");
	const logoFixedSrc = logos?.find(item => item.identifier === "logo-fixed");

	if (!logoSrc) {
		return <Log message={`ProfileData error: Logo with identifier "logo" missing`} />;
	}

	const Logo = () => {
		if (!headerScroll && layoutVariant !== "no-animation") {
			return (
				<ReactLink to="/">
					<img
						alt={name}
						className={clsx(css.image, "mco-view-component-header-logo__image")}
						height={layoutVariant !== "no-animation" ? 124 : 72}
						src={logoSrc?.filename}
						width={layoutVariant !== "no-animation" ? 300 : 174}
					/>
				</ReactLink>
			);
		} else {
			return (
				<ReactLink to="/">
					<img
						alt={name}
						className={clsx(css.image, "mco-view-component-header-logo__image")}
						height={layoutVariant === "fullscreen" ? 124 : 72}
						src={logoFixedSrc?.filename}
						width={layoutVariant === "fullscreen" ? 300 : 174}
					/>
				</ReactLink>
			);
		}
	};

	return (
		<div
			className={clsx(css.logo, "mco-view-component-header-logo", {
				[css.logoFullscreen]: layoutVariant !== "no-animation",
				[css.logoScroll]: headerScroll
			})}
		>
			{Logo()}
		</div>
	);
};

export default Logo;
