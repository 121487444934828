import React, { useContext, useEffect, useState } from "react";
import clsx from "clsx";
import vhCheck from "vh-check";
import PageContext from "@PageContext";
import ReactAnimation from "@micado-digital/react-animation/ReactAnimation";

import styles from "./Animation.styles";

const Animation = () => {
	const css = styles();
	const { REACT_APP_PATH } = process.env;
	const [textAnimation, setTextAnimation] = useState(false);
	const [pageData] = useContext(PageContext);
	const { animationID, layoutVariant } = pageData;
	vhCheck({
		bind: true
	});

	useEffect(() => {
		setTimeout(() => {
			setTextAnimation(true);
		}, 1000);
	}, []);

	return (
		<div
			className={clsx(css.animation, "mco-view-component-animation", {
				[css.fullscreen]: layoutVariant === "fullscreen",
				[css.headerVisible]: layoutVariant !== "no-animation",
				"mco-view-component-animation--text-visible": textAnimation
			})}
		>
			<ReactAnimation
				api={`${REACT_APP_PATH}/animationV3.json.api`}
				showBullets={true}
				id={animationID}
				mediaFormats={{
					xs:
						layoutVariant === "fullscreen"
							? "animation-fullscreen-mobile"
							: "animation-mobile",
					sm: layoutVariant === "fullscreen" ? "animation-fullscreen-hd" : "animation-hd",
					xl: layoutVariant === "fullscreen" ? "animation-fullscreen" : "animation"
				}}
				webpFallback
			/>
		</div>
	);
};

export default Animation;
