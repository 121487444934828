import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	row2: {
		alignItems: "center",
		background: "#FFFFFF",
		boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.15)",
		display: "flex",
		height: 72,
		position: "sticky",
		top: 0,
		zIndex: theme.zIndex.appBar,
		[theme.breakpoints.up("md")]: {
			height: 80
		},
		[theme.breakpoints.up("lg")]: {
			height: 96
		}
	},
	headerInvisible: {
		background: "none",
		boxShadow: "unset",
		"&:before": {
			background: "linear-gradient(180deg, #4B829B 50%, rgba(75, 130, 155, 0) 100%)",
			content: "''",
			height: 120,
			opacity: 1,
			position: "absolute",
			pointerEvents: "none",
			top: 0,
			transition: "opacity 300ms ease",
			width: "100%",
			[theme.breakpoints.up("md")]: {
				background:
					"linear-gradient(180deg, rgba(75, 130, 155, 1) 50%, rgba(75, 130, 155, 0) 100%)",
				height: 220,
				top: -35
			}
		}
	},
	menuVisible: {
		"&:before": {
			background:
				"linear-gradient(180deg, rgba(75, 130, 155, 1) 50%, rgba(75, 130, 155, 0) 100%)"
		}
	},
	headerScroll: {
		background: "#FFFFFF",
		boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.15)",
		"&:before": {
			opacity: 0
		}
	},
	container: {
		display: "flex",
		height: "100%",
		maxWidth: 1376,
		position: "relative",
		[theme.breakpoints.up("md")]: {
			paddingRight: 4
		}
	}
}));

export default styles;
