import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	jobs: {
		width: "50%",
		"& a": {
			alignItems: "center",
			display: "flex",
			height: 35,
			gap: theme.spacing(1),
			textTransform: "uppercase",
			"& svg path": {
				fill: "#4F545C",
				fillOpacity: 1
			}
		}
	}
}));

export default styles;
