import React, { useContext } from "react";
import clsx from "clsx";
import UiContext from "@UiContext";
import Hamburger from "./Hamburger";
import Level1 from "./Level1";
import Links from "./Links";
import Search from "../Search";
// import Lang from "../../../Row1/Lang";

import styles from "./MobileMenu.styles";

const Mobilemenu = ({ menuGroup }) => {
	const css = styles();
	const [ui, _setUi] = useContext(UiContext);
	const { menuOpen } = ui;
	const { items } = menuGroup;

	const setUi = () => {
		_setUi({ ...ui, menuOpen: !menuOpen });
	};

	return (
		<div className={clsx(css.mobilemenu, "mco-view-component-header-menu-mobilemenu")}>
			<Hamburger menuOpen={menuOpen} setMenuOpen={setUi} />
			{menuOpen && (
				<>
					<div
						className={clsx(
							css.container,
							"mco-view-component-header-menu-mobilemenu__container"
						)}
					>
						{/* <Lang /> */}
						<Search />
						<Level1 items={items} />
						<div
							className={clsx(css.bottom, "mco-view-component-header-menu-mobilemenu__bottom")}
						>
							<Links />
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default Mobilemenu;
