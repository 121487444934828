import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	level1: {
		height: "100%",
		marginTop: theme.spacing(7),
		overflow: "auto",
		paddingBottom: theme.spacing(11.5),
		scrollbarWidth: "none",
		"&::-webkit-scrollbar": {
			display: "none"
		},
		"& > div:last-child a": {
			borderBottom: 0
		},
		"& .mco-view-component-header-menu-mobilemenu-level1--active": {
			paddingBottom: theme.spacing(2),
			"& > a > .mco-view-component-header-menu-mobilemenu-menuitem__arrow": {
				transform: "rotate(180deg)"
			}
		}
	},
	level1Active: {
		"& > div": {
			opacity: 0.5
		},
		"& .mco-view-component-header-menu-mobilemenu-level1--active": {
			opacity: 1
		}
	}
}));

export default styles;
