import React, { useContext } from "react";
import clsx from "clsx";
import PageContext from "@PageContext";
import ReactLink from "@micado-digital/react-link/ReactLink";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";
import UiContext from "@UiContext";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import Local from "@Local";
import localDE from "@localDE";
import localEN from "@localEN";

import styles from "./Arrival.styles";

const Arrival = ({ link }) => {
	const css = styles();
	const [pageData] = useContext(PageContext);
	const [ui, setUi] = useContext(UiContext);
	const { lang } = pageData;
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});

	return (
		<div className={clsx(css.arrival, "mco-view-component-header-arrival")}>
			<ReactLink
				onClick={e => {
					setUi({ ...ui, menuOpen: false });
				}}
				to={link?.url}
			>
				<ReactSVGIcon src="/img/icons/arrival.svg" size={20} />
				<span className={clsx(css.label, "mco-view-component-header-arrival__label")}>
					<Local identifier="header.arrival" l={l} />
				</span>
			</ReactLink>
		</div>
	);
};

export default Arrival;
