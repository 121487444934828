import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	links: {
		borderTop: "1px solid #CCCCCC",
		alignItems: "flex-start",
		display: "flex",
		flexDirection: "column",
		gap: theme.spacing(2),
		margin: "0 -24px",
		padding: "24px 24px 2px",
		height: "100%",
		justifyContent: "center",
		position: "relative",
		"& span": {
			color: "#4F545C"
		},
		"& .mco-view-component-header-menu-mobilemenu__links-row01": {
			display: "flex",
			flexWrap: "wrap"
		},
		"& .mco-view-component-header-menu-mobilemenu__links-row02": {
			display: "flex",
			flexWrap: "wrap",
			marginTop: 8
		},
		"& .mco-view-component-header-menu-mobilemenu__links-row03": {
			position: "absolute",
			bottom: 8,
			right: 24
		},
		"& .mco-view-component-header-menu-mobilemenu__links-row02 a + a": {
			marginLeft: 18
		},
		"&:before": {
			background:
				"linear-gradient(180deg, rgba(245,245,245,0.5) 0%, rgba(245,245,245,1) 100%)",
			content: "''",
			height: 100,
			left: 0,
			position: "absolute",
			pointerEvents: "none",
			width: "100%",
			top: -101
		}
	}
}));

export default styles;
