import React, { useEffect, useState } from "react";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import MenuItem from "../MenuItem";
import Level3 from "../Level3";

import styles from "./Level2.styles";

const Level2 = ({ items }) => {
	const css = styles();
	const [submenuOpen, setSubmenuOpen] = useState({});

	const handleMenuItemClick = (e, item) => {
		if (!item?.items) return;
		e.preventDefault();

		setSubmenuOpen({ ...submenuOpen, [item.id]: !submenuOpen[item.id] });
	};

	useEffect(() => {
		// open submenu on start

		const currentItem = items?.find(item => item.selected && item.items?.length > 0);

		if (!isEmpty(currentItem)) {
			setSubmenuOpen({ ...submenuOpen, [currentItem.id]: true });
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={clsx(css.level2, "mco-view-component-header-menu-mobilemenu-level2")}>
			{items?.map(item => {
				const { id, items } = item;

				return (
					<div
						className={clsx({
							"mco-view-component-header-menu-mobilemenu-level2--active": submenuOpen[id]
						})}
						key={id}
					>
						<MenuItem item={item} level={2} onClick={e => handleMenuItemClick(e, item)} />
						{submenuOpen[id] && <Level3 items={items} />}
					</div>
				);
			})}
		</div>
	);
};

export default Level2;
