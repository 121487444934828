import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	hamburger: {
		alignItems: "center",
		cursor: "pointer",
		display: "flex",
		justifyContent: "center",
		position: "relative",
		width: 30,
		zIndex: 1
	}
}));

export default styles;
