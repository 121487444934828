import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	phone: {
		alignItems: "center",
		display: "flex",
		gap: theme.spacing(1),
		textTransform: "uppercase",
		"& svg path": {
			fill: theme.palette.primary.main,
			fillOpacity: 1
		}
	}
}));

export default styles;
