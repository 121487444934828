import React, { useContext } from "react";
import clsx from "clsx";
import PageContext from "@PageContext";
import ReactLink from "@micado-digital/react-link/ReactLink";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import useSystemLinks from "@micado-digital/react-ui/hooks/useSystemLinks";
import Local from "@Local";
import localDE from "@localDE";
import localEN from "@localEN";

import styles from "./Region.styles";

const Region = () => {
	const [pageData] = useContext(PageContext);
	const sysLink = useSystemLinks();
	const { lang } = pageData;
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});
	const css = styles();

	return (
		<div className={clsx(css.region, "mco-view-component-header-region")}>
			<ReactLink to={sysLink("header-region")}>
				<ReactSVGIcon src="/img/icons/region.svg" size={20} />
				<span className={clsx(css.label, "mco-view-component-header-region__label")}>
					<Local identifier="header.region" l={l} />
				</span>
			</ReactLink>
		</div>
	);
};

export default Region;
