import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	level1: {
		alignItems: "center",
		display: "flex",
		gap: theme.spacing(4)
	}
}));

export default styles;
