import React, { useContext } from "react";
import clsx from "clsx";
import WebProfileContext from "@WebProfileContext";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";

import styles from "./Mail.styles";

const Mail = () => {
	const [profileData] = useContext(WebProfileContext);
	const { mail } = profileData;
	const css = styles();

	return (
		<a
			className={clsx(css.mail, "mco-view-component-header-mail")}
			href={"mailto:" + mail}
		>
			<ReactSVGIcon src="/img/icons/mail-outlined.svg" size={32} />
		</a>
	);
};

export default Mail;
