import React, { useContext } from "react";
import clsx from "clsx";
import PageContext from "@PageContext";
import { useScrollYPosition } from "react-use-scroll-position";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";

import styles from "./Hamburger.styles";

const Hamburger = ({ menuOpen, setMenuOpen }) => {
	const [pageData] = useContext(PageContext);
	const { layoutVariant } = pageData;
	const css = styles();

	const scrollY = useScrollYPosition();
	const headerScroll = scrollY > 96;

	return (
		<div
			className={clsx(css.hamburger, "mco-view-component-header-menu-mobilemenu__hamburger")}
			onClick={() => setMenuOpen(!menuOpen)}
		>
			{menuOpen && <ReactSVGIcon color="primary" src="/img/icons/close.svg" size={24} />}
			{!menuOpen && (
				<ReactSVGIcon
					color={headerScroll || layoutVariant === "no-animation" ? "primary" : "paper"}
					src="/img/icons/menu.svg"
					size={24}
				/>
			)}
		</div>
	);
};

export default Hamburger;
