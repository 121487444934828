import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	logo: {
		alignItems: "center",
		display: "flex",
		transition: "transform 300ms ease",
		[theme.breakpoints.down("md")]: {
			"& img": {
				height: "100%",
				width: 120
			}
		}
	},
	image: {
		display: "block",
		transition: "all 100ms ease"
	},
	logoFullscreen: {
		display: "block",
		transform: "translate(0, 20px)",
		"& a": {
			display: "inline-block"
		},
		[theme.breakpoints.down("md")]: {
			"& img": {
				height: "100%",
				width: 193
			}
		},
		[theme.breakpoints.up("md")]: {
			transform: "translate(0, 0)"
		}
	},
	logoScroll: {
		display: "flex",
		alignItems: "center",
		transform: "unset",
		"& img": {
			height: "100%",
			width: 174
		},
		[theme.breakpoints.down("md")]: {
			"& img": {
				height: "100%",
				width: 120
			}
		}
	}
}));

export default styles;
