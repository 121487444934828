import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	desktopmenu: {
		alignItems: "center",
		display: "flex",
		flex: 1,
		justifyContent: "flex-end"
	},
	content: {
		display: "flex",
		[theme.breakpoints.up("lg")]: {
			marginTop: theme.spacing(2)
		},
		"& .mco-view-component-header-search": {
			marginLeft: theme.spacing(2.5),
			padding: theme.spacing(1),
			"& .mco-view-component-header-search__label": {
				display: "none"
			},
			"& svg path": {
				fill: "#FFFFFF"
			}
		},
		"&.mco-view-component-header-menu-desktopmenu__content--scroll": {
			[theme.breakpoints.up("lg")]: {
				marginTop: theme.spacing(0)
			},
			"& .mco-view-component-header-search": {
				"& svg path": {
					fill: theme.palette.primary.main
				}
			}
		}
	}
}));

export default styles;
