import React, { useContext } from "react";
import clsx from "clsx";
import { useScrollYPosition } from "react-use-scroll-position";
import PageContext from "@PageContext";
import UiContext from "@UiContext";
import ReactLink from "@micado-digital/react-link/ReactLink";

import styles from "./MenuItem.styles";

const MenuItem = ({
	hasChildren,
	item,
	level,
	onClick,
	onMouseEnter,
	onMouseLeave,
	selected
}) => {
	const css = styles();
	const [ui, setUi] = useContext(UiContext);
	const [pageData] = useContext(PageContext);
	const scrollY = useScrollYPosition();
	const headerScroll = scrollY > 96;

	const { link, linkRel, target, title } = item;

	return (
		<ReactLink
			className={clsx(css.menuItem, "mco-view-component-header-menu-desktopmenu-menuitem", {
				[css.level1]: level === 1,
				"mco-view-component-header-menu-desktopmenu-menuitem--scroll":
					headerScroll || pageData?.layoutVariant === "no-animation",
				"mco-view-component-header-menu-desktopmenu-menuitem__level1": level === 1,
				[css.level1Selected]: level === 1 && selected,
				"mco-view-component-header-menu-desktopmenu-menuitem__level1--selected":
					level === 1 && selected,
				[css.level2]: level === 2,
				"mco-view-component-header-menu-desktopmenu-menuitem__level2": level === 2,
				[css.level2Selected]: level === 2 && selected,
				"mco-view-component-header-menu-desktopmenu-menuitem__level2--selected":
					level === 2 && selected,
				[css.level3]: level === 3,
				"mco-view-component-header-menu-desktopmenu-menuitem__level3": level === 3,
				[css.level3Selected]: level === 3 && selected,
				"mco-view-component-header-menu-desktopmenu-menuitem__level3--selected":
					level === 3 && selected
			})}
			onClick={e => {
				if (hasChildren) {
					e.preventDefault();
					onClick(item.id);
					return;
				}
				setUi({ ...ui, submenuOpen: false });
			}}
			onMouseEnter={e => onMouseEnter && onMouseEnter(e, item)}
			onMouseLeave={e => onMouseLeave && onMouseLeave(e, item)}
			rel={linkRel}
			target={target}
			to={link}
		>
			{title}
		</ReactLink>
	);
};

export default MenuItem;
