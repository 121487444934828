import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import CssBaseline from "@material-ui/core/CssBaseline";
import createTheme from "@material-ui/core/styles/createTheme";
import ThemeProvider from "@material-ui/styles/ThemeProvider";
import { theme as customTheme } from "./themes/medicubus";
import Ui from "@micado-digital/react-ui/components/Ui";
import WebPageData from "@micado-digital/react-ui/components/WebPageData";
import Views from "./views";
import {
	applyPolyfills as ialApplyPolyfills,
	defineCustomElements as ialDefineCustomElements
} from "@micado-digital/micado-ial/loader";
import "./css/index.css";

const theme = createTheme(customTheme);

const App = () => {
	return (
		<ThemeProvider theme={theme}>
			<Suspense fallback={<></>}>
				<CssBaseline />
				<BrowserRouter>
					<Ui>
						<WebPageData>
							<Routes>
								<Route path="/*" element={<Views />} />
							</Routes>
						</WebPageData>
					</Ui>
				</BrowserRouter>
			</Suspense>
		</ThemeProvider>
	);
};

ReactDOM.render(<App />, document.getElementById("root"));

ialApplyPolyfills().then(() => {
	ialDefineCustomElements(window);
});
