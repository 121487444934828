import React, { useContext } from "react";
import clsx from "clsx";
import PageContext from "@PageContext";
import AccountData from "@micado-digital/react-ui/components/AccountData";
import ConsentData from "@micado-digital/react-ui/components/ConsentData";
import WebProfile from "@micado-digital/react-ui/components/WebProfile";
import Locals from "@micado-digital/react-ui/components/Locals";
import Default from "./Default";
import Core from "./Core";
import useScrollToLocation from "../hooks/useScrollToLocation";

import styles from "./Views.styles";

const Views = () => {
	const css = styles();
	const [pageData] = useContext(PageContext);
	const { authorID, lang, layoutTag } = pageData;

	useScrollToLocation(150);

	let content = null;

	switch (layoutTag) {
		case "standard":
			content = (
				<div className={clsx(css.page, "mco-page")}>
					<Default />
				</div>
			);
			break;
		default:
	}

	return (
		<WebProfile key={lang}>
			<ConsentData lang={lang}>
				<AccountData authorID={authorID}>
					<Locals url={`/locals/${lang || "de"}.json`}>
						{content}
						<Core />
					</Locals>
				</AccountData>
			</ConsentData>
		</WebProfile>
	);
};

export default Views;
