import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	level2: {
		borderBottom: "1px solid " + theme.palette.divider,
		paddingBottom: theme.spacing(2),
		"& .mco-view-component-header-menu-mobilemenu-level2--active": {
			"& .mco-view-component-header-menu-mobilemenu-menuitem__arrow": {
				transform: "rotate(180deg)"
			}
		}
	}
}));

export default styles;
