import React, { useEffect, useRef, useState } from "react";
import clsx from "clsx";
import isEmpty from "lodash/isEmpty";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import MenuItem from "../MenuItem";
import Level2 from "../Level2";

import styles from "./Level1.styles";

const MobileContainer = ({ items }) => {
	const css = styles();
	const [submenuOpen, setSubmenuOpen] = useState({});
	const $level1 = useRef(null);

	const handleMenuItemClick = (e, item) => {
		if (!item?.items) return;
		e.preventDefault();

		setSubmenuOpen({ ...submenuOpen, [item.id]: !submenuOpen[item.id] });
	};

	useEffect(() => {
		disableBodyScroll($level1.current);

		// open submenu on start

		const currentItem = items?.find(item => item.selected && item.items?.length > 0);

		if (!isEmpty(currentItem)) {
			setSubmenuOpen({ ...submenuOpen, [currentItem.id]: false });
		}

		return () => {
			clearAllBodyScrollLocks();
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div
			className={clsx(css.level1, "mco-view-component-header-menu-mobilemenu-level1")}
			ref={$level1}
		>
			{items?.map(item => {
				const { id, items } = item;

				return (
					<div
						className={clsx({
							"mco-view-component-header-menu-mobilemenu-level1--active": submenuOpen[id]
						})}
						key={id}
					>
						<MenuItem
							childrenVisible={submenuOpen[id]}
							item={item}
							level={1}
							onClick={e => handleMenuItemClick(e, item)}
						/>
						{submenuOpen[id] && <Level2 items={items} />}
					</div>
				);
			})}
		</div>
	);
};

export default MobileContainer;
