export const theme = {
	typography: {
		fontFamily: "Lato",
		fontSize: 16,
		fontWeightLight: 300,
		fontWeightRegular: 400,
		fontWeightMedium: 500,
		fontWeightBold: 700,
		h1: {
			fontSize: "clamp(32px, 8vw, 48px)",
			fontWeight: 400,
			lineHeight: 1.2
		},
		h2: {
			fontSize: "clamp(26px, 8vw, 32px)",
			fontWeight: 400,
			lineHeight: 1.2
		},
		h3: {
			fontSize: "clamp(24px, 8vw, 28px)",
			fontWeight: 400,
			lineHeight: 1.2
		},
		h4: {
			fontSize: "clamp(22px, 8vw, 26px)",
			fontWeight: 400,
			lineHeight: 1.2
		},
		h5: {
			fontSize: "clamp(20px, 8vw, 24px)",
			fontWeight: 400,
			lineHeight: 1.2
		},
		h6: {
			fontSize: "clamp(18px, 8vw, 20px)",
			fontWeight: 400,
			lineHeight: 1.2
		},
		body1: {
			fontWeight: 400,
			fontSize: 16,
			lineHeight: 1.5
		},
		body2: {
			fontWeight: 400,
			fontSize: 14,
			lineHeight: 1.5
		},
		subtitle1: {
			fontWeight: 700,
			fontSize: 16,
			lineHeight: 1.5
		},
		subtitle2: {
			fontWeight: 700,
			fontSize: 14,
			lineHeight: 1.5
		},
		overline: {
			fontWeight: 400,
			fontSize: 16,
			letterSpacing: "0.05em",
			lineHeight: 1.5,
			textTransform: "uppercase"
		},
		caption: {
			fontWeight: 400,
			fontSize: 12,
			letterSpacing: "0.4px",
			lineHeight: 1.5,
			textTransform: "uppercase"
		},
		button: {
			fontSize: 16,
			fontWeight: 700,
			letterSpacing: "0.05em",
			lineHeight: 1
		}
	},
	palette: {
		primary: {
			light: "#3381B7",
			main: "#27638B",
			dark: "#164F69"
		},
		secondary: {
			light: "#ACC6D2",
			main: "#7AA4B7",
			dark: "#164F69"
		},
		error: {
			light: "#F08080",
			main: "#D50B0B",
			dark: "#8B0101"
		},
		warning: {
			light: "#FFDE77",
			main: "#FFC105",
			dark: "#9F7804"
		},
		info: {
			light: "#76B584",
			main: "#00751B",
			dark: "#014911"
		},
		success: {
			light: "#B2D975",
			main: "#71B800",
			dark: "#477301"
		},
		grey: {
			50: "#F9FAFA",
			100: "#F1F3F3",
			200: "#D6DADC",
			300: "#ACB5B9",
			400: "#919CA1",
			500: "#75838A",
			600: "#5E696E",
			700: "#464F53",
			800: "#2F3437",
			900: "#171A1C",
			A100: "rgba(0, 0, 0, 0.1)",
			A200: "rgba(0, 0, 0, 0.3)",
			A400: "rgba(0, 0, 0, 0.5)",
			A700: "rgba(0, 0, 0, 0.8)"
		},
		text: {
			primary: "#4F545C",
			secondary: "#848890",
			disabled: "#BEBFC1"
		},
		divider: "#CCCCCC",
		background: {
			backdrop: "rgba(0, 0, 0, 0.5)",
			body: "#FFFFFF",
			footer: "#083245",
			header: "#FFFFFF",
			menu: "#F5F5F5",
			teaser: "#EAEDEF",
			default: "#FFFFFF"
		},
		brand: {
			brand1: "#7AA4B7",
			brand2: "#9B9B9B"
		}
	},
	shape: {
		borderRadius: 0
	},
	overrides: {
		MuiButton: {
			root: {
				borderRadius: 64,
				height: "48px !important",
				padding: "12px 24px !important",
				textTransform: "unset !important"
			},
			sizeSmall: {
				fontSize: 14,
				fontWeight: 700,
				height: "40px !important",
				letterSpacing: "0.05em"
			},
			sizeLarge: {
				fontSize: 14,
				fontWeight: 700,
				letterSpacing: "0.05em"
			},
			outlinedPrimary: {
				borderColor: "#27638B !important",
				borderWidth: 2,
				padding: "10px 16px !important",
				"&:hover": {
					background: "#27638B !important",
					borderColor: "#27638B !important",
					borderWidth: 2,
					color: "#FFFFFF !important"
				}
			},
			containedPrimary: {
				boxShadow: "unset !important"
			}
		},
		MuiAlert: {
			root: {
				alignItems: "center"
			}
		}
	},
	customTypography: {
		menu: {
			mobile: {
				level1: {
					fontWeight: 700,
					fontSize: 20,
					lineHeight: 1.4
				},
				level2: {
					fontWeight: 700,
					fontSize: 16,
					letterSpacing: "0.02em",
					lineHeight: 1.4
				},
				level3: {
					fontWeight: 400,
					fontSize: 16,
					lineHeight: 1.4
				}
			},
			desktop: {
				level1: {
					fontWeight: 700,
					fontSize: 20,
					lineHeight: 1.4
				},
				level2: {
					fontWeight: 700,
					fontSize: 16,
					lineHeight: 1.4
				},
				level3: {
					fontWeight: 400,
					fontSize: 16,
					lineHeight: 1.4
				}
			}
		},
		footer: {
			footermenu: {
				level1: {
					fontWeight: 700,
					fontSize: 12,
					lineHeight: 1.2,
					textTransform: "uppercase"
				},
				level2: {
					fontSize: 16,
					fontWeight: 400,
					lineHeight: 1.5
				}
			},
			metamenu: {
				fontWeight: 400,
				fontSize: 12,
				letterSpacing: "0.02em",
				lineHeight: 1.333,
				textTransform: "uppercase"
			}
		}
	}
};
