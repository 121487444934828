import { useEffect } from "react";

const useScrollToLocation = offset => {
	useEffect(() => {
		const scrollToHashElement = () => {
			const { hash } = window.location;
			const elementToScroll = document.getElementById(hash?.replace("#", ""));

			if (!elementToScroll) return;

			window.scrollTo({
				top: elementToScroll.offsetTop - offset,
				behavior: "smooth"
			});
		};

		setTimeout(() => {
			scrollToHashElement();
			window.addEventListener("hashchange", scrollToHashElement);
			return window.removeEventListener("hashchange", scrollToHashElement);
		}, 500);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};

export default useScrollToLocation;
