import React, { useContext, useEffect } from "react";
import useTheme from "@material-ui/core/styles/useTheme";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import PageContext from "@PageContext";
import Desktopmenu from "./Desktopmenu";
import Mobilemenu from "./Mobilemenu";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import duplicateParentAsFirstChild from "./utils/duplicateParentAsFirstChild";
import localDE from "@localDE";
import localEN from "@localEN";

const Menu = ({ headerScroll, submenuVisible }) => {
	const [pageData] = useContext(PageContext);
	const { lang = "de", menuGroups } = pageData;
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
	const mainMenuGroup = menuGroups?.find(menuGroup => menuGroup?.title === "Hauptmenü");
	const { items } = mainMenuGroup || {};

	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});

	const submenuOpen = state => {
		submenuVisible(state);
	};

	useEffect(() => {
		if (isMobile) {
			duplicateParentAsFirstChild(items, l("menu.overview"));
		}
	}, [items, l, isMobile]);

	return (
		<>
			{isMobile ? (
				<Mobilemenu menuGroup={mainMenuGroup} />
			) : (
				<Desktopmenu
					headerScroll={headerScroll}
					menuGroup={mainMenuGroup}
					submenuVisible={submenuOpen}
				/>
			)}
		</>
	);
};

export default Menu;
