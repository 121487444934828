import React, { useContext } from "react";
import clsx from "clsx";
import PageContext from "@PageContext";
import ReactLink from "@micado-digital/react-link/ReactLink";
import ReactSVGIcon from "@micado-digital/react-svg-icon/ReactSVGIcon";
import useLocals from "@micado-digital/react-ui/hooks/useLocals";
import useSystemLinks from "@micado-digital/react-ui/hooks/useSystemLinks";
import Local from "@Local";
import localDE from "@localDE";
import localEN from "@localEN";

import styles from "./Arrival.styles";

const Arrival = () => {
	const css = styles();
	const [pageData] = useContext(PageContext);
	const sysLink = useSystemLinks();
	const { lang } = pageData;
	const l = useLocals({
		lang: lang,
		res: {
			de: localDE,
			en: localEN
		}
	});

	return (
		<div className={clsx(css.arrival, "mco-view-component-header-arrival")}>
			<ReactLink to={sysLink("header-arrival")}>
				<ReactSVGIcon src="/img/icons/arrival.svg" size={20} />
				<span className={clsx(css.label, "mco-view-component-header-arrival__label")}>
					<Local identifier="header.arrival" l={l} />
				</span>
			</ReactLink>
		</div>
	);
};

export default Arrival;
