import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	menuItem: {
		color: theme.palette.primary.main
	},
	level1: {
		borderBottom: "2px solid transparent",
		color: "#FFFFFF",
		...theme?.customTypography?.menu?.desktop?.level1,
		display: "block",
		padding: theme.spacing(1, 0),
		"&:hover": {
			borderBottom: "2px solid #FFFFFF"
		},
		"&.mco-view-component-header-menu-desktopmenu-menuitem--scroll": {
			color: theme.palette.primary.main,
			"&:hover": {
				borderBottom: "2px solid " + theme.palette.primary.main
			},
			"&.mco-view-component-header-menu-desktopmenu-menuitem__level1--selected": {
				borderBottom: "2px solid " + theme.palette.primary.main
			}
		}
	},
	level1Selected: {
		borderBottom: "2px solid #FFFFFF"
	},
	level2: {
		color: theme.palette.text.primary,
		display: "inline-block",
		...theme?.customTypography?.menu?.desktop?.level2,
		marginBottom: theme.spacing(3),
		"&:hover": {
			color: theme.palette.primary.main
		}
	},
	level2Selected: {
		color: theme.palette.text.primary,
		fontWeight: 700
	},
	level3: {
		color: theme.palette.text.primary,
		display: "inline-block",
		...theme?.customTypography?.menu?.desktop?.level3,
		marginBottom: theme.spacing(1.5),
		"&:hover": {
			color: theme.palette.primary.main
		}
	},
	level3Selected: {
		color: theme.palette.primary.main,
		fontWeight: 700
	}
}));

export default styles;
