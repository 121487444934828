import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	menuItem: {
		alignItems: "center",
		color: theme.palette.primary.main,
		display: "flex",
		justifyContent: "space-between"
	},
	level1: {
		color: theme.palette.primary.dark,
		...theme?.customTypography?.menu?.mobile?.level1,
		padding: theme.spacing(1, 0)
	},
	level1Selected: {
		"&:before": {
			background: theme.palette.primary.main,
			content: "''",
			height: 28,
			left: 0,
			position: "absolute",
			width: 2
		}
	},
	level2: {
		color: theme.palette.text.primary,
		...theme?.customTypography?.menu?.mobile?.level2,
		padding: theme.spacing(1.25, 0)
	},
	level2Selected: {
		color: theme.palette.primary.main,
		fontWeight: 700
	},
	level3: {
		color: theme.palette.text.primary,
		...theme?.customTypography?.menu?.mobile?.level3,
		padding: theme.spacing(0.75, 0)
	},
	level3Selected: {
		color: theme.palette.primary.main,
		fontWeight: 700
	},
	arrow: {
		transition: "transform 300ms ease"
	},
	childrenVisible: {
		borderBottomColor: "transparent !important"
	}
}));

export default styles;
