import makeStyles from "@material-ui/styles/makeStyles";

const styles = makeStyles(theme => ({
	row1: {
		alignItems: "center",
		color: "#FFFFFF",
		display: "none",
		height: 35,
		position: "relative",
		width: "100%",
		zIndex: theme.zIndex.appBar + 1,
		[theme.breakpoints.up("md")]: {
			display: "flex"
		},
		"&:before": {
			background: "linear-gradient(270deg, rgba(0, 0, 0, 0.6) 49.51%, rgba(0, 0, 0, 0) 100%)",
			content: "''",
			height: "100%",
			position: "absolute",
			top: 0,
			right: 0,
			width: "75%",
			zIndex: -1
		}
	},
	container: {
		alignItems: "center",
		display: "flex",
		gap: theme.spacing(3),
		height: "100%",
		justifyContent: "flex-end",
		marginLeft: "auto",
		marginRight: "auto",
		maxWidth: 1376
	}
}));

export default styles;
