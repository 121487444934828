import React, { useContext } from "react";
import loadable from "@loadable/component";
import PageContext from "@PageContext";
import Header from "@Components/views/Header";
import Animation from "@Components/views/Animation";
import Elements from "@Components/Elements";
import PageTransition from "@micado-digital/react-ui/components/PageTransition";

const Footer = loadable(() => import("@Components/views/Footer"));
const AnchorMenu = loadable(() => import("../../components/views/AnchorMenu"));

const Default = () => {
	const { REACT_APP_PATH } = process.env;
	const [pageData] = useContext(PageContext);
	const { layoutVariant } = pageData;
	const { lang } = pageData;

	const anchorItems =
		pageData?.elements?.filter(({ anchor }) => anchor?.visible)?.map(item => item?.anchor) ??
		[];

	return (
		<>
			<Header />
			{anchorItems && anchorItems?.length > 0 && <AnchorMenu items={anchorItems} />}
			{layoutVariant !== "no-animation" && <Animation />}
			<PageTransition color="rgba(0,0,0,0.2)" position="top">
				<Elements />
			</PageTransition>
			<Footer />
			<micado-ial language={lang} path={REACT_APP_PATH} page-id={pageData?.id}></micado-ial>
		</>
	);
};

export default Default;
